.categoryDetailsContainer {}

.searchInput {
    background-color: #E9E9E9;
    border: none;
    outline: none;
    outline: none;
    padding: 5px;
    border-radius: 5px;
    width: 80%;
}

.wrapper button {
    background-color: orangered;
}

.search-filters-container input[type=checkbox] {
    margin-right: 10px;
}