.profileSectionContainer h6,
p {
    text-transform: none !important;
}

.itemcard {
    background: #fff;
    margin: 0 0 30px;
    height: 85%;
    min-height: 140px;
    padding: 15px;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1px 5px 0 rgb(50 50 50 / 25%), 0 0px 0px 1px rgb(50 50 50 / 3%);
    display: block;
    transition: 0.15s all ease;
    transform: translateY(0);
}

.cards-background {
    background-color: #F5F5F5;
    padding: 20px;
}

.iconContainer {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #eee;
    border: 1px solid #d2d2d2;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconContainer i {
    font-size: 20px;
    margin-top: -20px;
}

.profileSectionContainer .list-inline {
    display: flex;
    justify-content: space-evenly;
    margin: 0 -1px -1px;
    width: calc(100% + 2px);
    border-radius: 0 0 3px 3px;
    overflow: hidden;
    background-color: #f33333;
    padding: 10px 0;

}

.profileSectionContainer .panel-heading {
    padding: 20px 0px 0px 0px !important;
}

.profileSectionContainer .list-inline a {
    color: #fff !important;
    text-decoration: none;
}