.benefits-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1300px;
    background-color: white;
    padding: 20px;
    /* box-shadow: 0 2px 5px rgba(0,0,0,0.1); */
}
.benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 15px;
}
.benefit-icon {
    font-size: 24px;
    color: #ffd700;
    margin-bottom: 10px;
}
.benefit-title {
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 12px;
}
.benefit-description {
    font-size: 14px;
    color: #666;
}
h1,
h1+p {
	margin: 30px 15px 0;
	font-weight: 300;
}
h1+p a {
	color: #333;
}
h1+p a:hover {
	text-decoration: none;
}
h2 {
	margin: 60px 15px 0;
	padding: 0;
	font-weight: 300;
}
h2 span {
	margin-left: 1em;
	color: #aaa;
	font-size: 85%;
}
.column {
	margin: 15px 15px 0;
	padding: 0;
}
.column:last-child {
	padding-bottom: 60px;
}
.column::after {
	content: '';
	clear: both;
	display: block;
}
.column div {
	position: relative;
	float: left;
	width: 300px;
	height: 200px;
	margin: 0 0 0 25px;
	padding: 0;
}
.column div:first-child {
	margin-left: 0;
}
.column div span {
	position: absolute;
	bottom: -20px;
	left: 0;
	z-index: -1;
	display: block;
	width: 300px;
	margin: 0;
	padding: 0;
	color: #444;
	font-size: 18px;
	text-decoration: none;
	text-align: center;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	opacity: 0;
}
figure {
	width: 300px;
	height: 200px;
	margin: 0;
	padding: 0;
	background: #fff;
	overflow: hidden;
}
figure:hover+span {
	bottom: -36px;
	opacity: 1;
}




.category-container {
    display: flex;
    gap: 10px;
}
.category-item {
    width: 140px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-align: center;
}
.icon-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.category-name {
    font-size: 14px;
    color: #333;
}/* Hover effect */
.category-item:hover {
    transform: translateY(-9px);
    /* transform: translateY(9px); */
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    /* -webkit-transition: .5s ease-in-out; */
	transition: .9s ease-in-out;
}
.category-item:hover .icon-circle {
    transform: scale(1.2);
    transition: .9s ease-in-out;
}
/* Specific styles for each category */
.vegetables { background-color: #e8f5e9; }
.vegetables .icon-circle { background-color: #c8e6c9; }
.fresh-bakery { background-color: #fff3e0; }
.fresh-bakery .icon-circle { background-color: #ffe0b2; }
.fresh-meat { background-color: #fce4ec; }
.fresh-meat .icon-circle { background-color: #f8bbd0; }
.fresh-milk { background-color: #e3f2fd; }
.fresh-milk .icon-circle { background-color: #bbdefb; }
.sea-foods { background-color: #f1f8e9; }
.sea-foods .icon-circle { background-color: #dcedc8; }
.fruit-drink { background-color: #fff0f4; }
.fruit-drink .icon-circle { background-color: #fce4ec; }
.green-salad { background-color: #e8f5e9; }
.green-salad .icon-circle { background-color: #c8e6c9; }

/* Existing styles */

.benefits-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 20px;
	padding: 20px;
  }
  
  .benefit-item {
	display: flex;
	align-items: center;
	flex: 1 1 200px;
	max-width: 300px;
  }
  
  .benefit-icon {
	font-size: 24px;
	margin-right: 10px;
  }
  
  .benefit-text {
	display: flex;
	flex-direction: column;
  }
  
  .benefit-title {
	font-weight: bold;
  }
  
  .category-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	padding: 20px;
  }
  
  .category-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 150px;
	max-width: 200px;
  }
  
  .icon-circle {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #f0f0f0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	margin-bottom: 10px;
  }
  
  .category-name {
	text-align: center;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
	.benefits-container {
	  flex-direction: column;
	  align-items: center;
	}
  
	.benefit-item {
	  max-width: 100%;
	}
  
	.category-container {
	  justify-content: space-around;
	}
  
	.category-item {
	  flex: 1 1 120px;
	  max-width: 150px;
	}
  }
  
  @media (max-width: 480px) {
	.benefit-item {
	  flex-direction: column;
	  text-align: center;
	}
  
	.benefit-icon {
	  margin-right: 0;
	  margin-bottom: 10px;
	}
  
	.category-item {
	  flex: 1 1 100px;
	  max-width: 120px;
	}
  
	.icon-circle {
	  width: 50px;
	  height: 50px;
	  font-size: 20px;
	}
  
	.category-name {
	  font-size: 14px;
	}
  }