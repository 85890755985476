body{

    font-family: Bai Jamjuree, sans-serif;
}


.bb {
    width: 100%;
    border-bottom: 1px solid rgb(0 0 0 / 11%);
}

.top-header .fa {
    color: #F33333;
    margin-left: 20px;
}

.mainwrap {
    display: flex;
    position: relative;
    align-items: center;

}

.small {
    font-size: 14px;
    color: rgb(87, 80, 80);
    font-weight: 400;
    margin-left: 5px;
}

.wrap {
    visibility: hidden;
    position: absolute;
    left: 10%;
    top: 230%;
    z-index: 300;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    transition: all 0.2s ease-out;
    box-shadow: 0 0px 5px 0px rgb(0 0 0 / 20%);
    cursor: pointer;


}

.mainwrap:hover .wrap {
    visibility: visible;
    position: absolute;
    left: 10%;
    opacity: 1;
    top: 130%;
    background-color: #fff;
    box-shadow: 0 0px 5px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    width: 100%;
}


.fa-chevron-down {
    color: rgb(70, 65, 65);
    font-size: 10px;
    font-weight: 400;
}

.small-gap {
    width: 15px;
}

.top-header span {
    margin-left: 20px !important;
    font-size: 10px !important;
}

.language {
    outline: none;
    border: none;
    border-radius: 5px;
    margin-left: 20px;
    padding: 2px 5px;
    color: rgb(87, 87, 87);
}
.linkColor{
    color: black;
    margin-right: 50px;
    font-family: Bai Jamjuree, sans-serif ;
}
.container {
    max-width: 100%;
  }
  
  .linkColor {
    margin: 0 10px;
    text-decoration: none;
    color: #000; /* Customize the color as needed */
  }
  
  .linkColor:hover {
    text-decoration: underline;
  }
  
  .language {
    margin-left: 15px;
  }
  
  @media (max-width: 767px) {
    .container {
      padding: 5px;
    }
  
    .linkColor {
      margin: 0 5px;
    }
  
    .language {
      margin-left: 5px;
    }
  
    .row {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  