.banner {
  padding: 40px;
  position: relative;
  display: flex;
  justify-content: center;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer :hover {
  color: red;
}

.cardTitle {
  color: rgb(156, 150, 150);
}

.product-details-banner-wrapper {
  height: 190px;
  position: relative;
}

.banner-inner {
  text-align: center;
}

.banner-inner a {
  text-decoration: none;
  color: inherit;
}

.banner-inner>div * {
  margin: 0 5px;
}

.banner-inner>div .fa-home {
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.banner-inner>div .fa-chevron-right {
  font-size: 12px;
  cursor: pointer;
}

.banner-inner>div span {
  color: #f33333;
  font-weight: 500;
}

.banner-inner>div .fa-home:hover {
  color: #f33333;
}


@media (max-width: 576px) {
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #fff;
  }
}


@media (max-width: 768px) {
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #fff;
  }
}


@media (min-width: 768px) {
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #fff;
  }
}
