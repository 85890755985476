.mainmodal{
    display: flex;
    background-color: #f33333;
}
.mainmodal .fa {
    padding: 5px;
    font-size: 30px;
}
.modalTitle{
    font-size: 16px;
    font-weight: 700;
    color: white;
    padding: 10px;
}
.mail{
    padding-top: 20px;
}
.input-group{
    margin-top: 15px;
}

.modal-content{
    width: 115%;
}  
.loginaccount{
    border-right: 1px solid black;
    padding: 10px;
}
.icon1{
    margin-left: 20px;
}
.icon1 a{
    border: 2px solid;
    color: white;
    padding: 4px;
    width: 100%;
    border-radius: 5px;
}
.hoverColor:hover{
    color: #f33333;
}
.hoverColor{
    margin-top: 10px;
    color: black;
}
.createAccount{
    margin-top: 20px;
}
.icon1 i{
    width: 15%;
}
.createAccount h5{
    color: rgb(145, 144, 143);
}
.createAccount li{
    color: rgb(145, 144, 143);
}
.close{
    margin-left: auto;
    cursor: pointer;
}
.btn-outline-primary
{
    background-color:rgb(145, 144, 143); 
    color: white;
    border: none;
}
.tabs{
    display: flex;
    justify-content: space-evenly;
    font-family: none;
}
.tab{
    padding: 5px !important;
}
.buttons{
    background-color: rgb(184, 14, 187) !important;
    border: none !important;

}