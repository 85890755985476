.table thead {
    background-color: #d4d4d4;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#couponCode {
    /* border: 2px solid red; */
    border: none;
    width: 80%;
    background-color: #E9E9E9;
    padding: 10px;
    outline: none;
    border-radius: 5px 0 0 5px;
}

#giftCertificate {
    /* border: 2px solid red; */
    border: none;
    width: 80%;
    background-color: #E9E9E9;
    padding: 10px;
    outline: none;
    border-radius: 5px 0 0 5px;
}

.shipping {
    border: none;
    width: 100%;
    background-color: #E9E9E9;
    padding: 10px;
    outline: none;
    border-radius: 5px 0 0 5px;
}


.applyCoupon {
    /* border: 2px solid red; */
    border: none;
    padding: 10px;
    background-color: rgb(167, 167, 167);
    transition: all 0.3s;
    border-radius: 0 5px 5px 0;
    color: #fff;
}

.applyCoupon:hover {
    background-color: rgb(66, 66, 66);
}

.getQuotesBtn {
    background-color: rgb(167, 167, 167);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    transition: all 0.3s;
}

.getQuotesBtn:hover {
    background-color: rgb(66, 66, 66);

}