.product-details-main {
    /* border: 2px solid red; */
}

.product-carousel-image-container {

    width: 100%;
    position: relative;
    height: 400px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0 100%;
    -webkit-background-size: 0 100%;
}

.product-carousel-image-container>img {
    /* height: 400px;
    max-height: 100%;
    max-width: 70%;
    width: 400px; */
    /* height: auto; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
}

.product-carousel-image-container .zoom {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    background: #fff;
    border: 1px solid #ccc;
}

.navigator-container {}

.navigator-container img {
    height: 100px;
    border: 1px solid rgb(230, 228, 228);
    border-radius: 5px;
    cursor: pointer;
}

.cardTitle~.btn {
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-details-main p {
    font-size: 15px;
    margin: 0;
}

.customTabs .nav-link {
    color: inherit;
}

.customTabs .nav-link:hover {
    color: rgb(252, 67, 0);
    background-color: rgba(0, 0, 0, 0.048);
}


/* 
@media (max-width: 768px) {
    .product-carousel-image-container>img {
      height: 270px;
      width: 200px;
    }
    .product-carousel-image-container{
        height: 270px;
    }
  } */
