.new-arrivals-container {}

.new-arrivals-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.new-arrivals-container ul li {
    margin: 0 10px;
    padding: 5 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.new-arrivals-container ul li:hover {
    color: #F33333;
}


.newArrivalsCardContainer {
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    position: relative;
    z-index: 3;
    height: 410px;
}

.newArrivalsCardContainer img {
    cursor: pointer;
}

.tools {
    position: absolute;
    padding: 20px 0;
    background-color: #f33333e0;
    top: 65%;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: all 0.4s;
    cursor: pointer;

}

.tools .fa {
    font-size: 16px;
    padding: 0 40px;
    color: #fff;
    border-left: 1px solid rgba(255, 255, 255, 0.514);
}

.tools .fa:first-child {
    border-left: none;

}

.newArrivalsCardContainer:hover .tools {
    transform: translateY(-60px);
}

.body-overlay {
    left: 0;
    bottom: 6px;
    width: 100%;
    height: 35%;
    padding: 10px 0;
    background-color: #fff;
    position: absolute;
    z-index: 2;
}

.newArrivalsCardContainer:hover {
    border: 1px solid #F33333;
}

.stars .fa-star {
    color: rgb(197, 197, 197);
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin: 0 2px;
}

.newArrivalsCardContainer .title {
    color: rgb(122, 121, 121);
    font-weight: 600;
    cursor: pointer;
}

.newArrivalsCardContainer .title a {
    text-decoration: none;
    color: inherit;
}

.newArrivalsCardContainer .title:hover {
    color: #F33333;
}

.newArrivalsCardContainer .price {
    color: #F33333;
    font-weight: 600;
}



.newArrivalsCardContainer h5 {
    line-height: 1.5;
}

.addCart {
    color: rgb(122, 121, 121);
    font-weight: 600;
    border: 1px solid rgb(201, 201, 201);
    width: 50%;
    margin: 0 auto;
    border-radius: 20px;
    padding: 8px 0;
    transform: translateY(calc(50% + 6px));
    background-color: #fff;
    cursor: pointer;
}

.newArrivalsCardContainer:hover .addCart {
    background-color: #F33333;
    color: #fff;
    border: none;
}