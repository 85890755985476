.newShoppingsCardContainer {
    border-right: 1px solid rgb(201, 201, 201);
    width: 100%;
    padding: 10px;
    position: relative;
    z-index: 3;
    height: 200px;
}

.newShoppingsCardContainer:hover {
    border-bottom: 1px solid #F33333;
}


.newShoppingsCardContainer .title {
    color: rgb(32, 31, 31);
    font-weight: 600;
    cursor: pointer;
}


.newShoppingsCardContainer .pragrap {
    color: rgb(138, 132, 132);
    font-weight: 600;
}



.newShoppingsCardContainer h5 {
    line-height: 1.5;
}

.newShoppingsCardContainer .fa {
    font-size: 30px;
    display: flex;
    justify-content: center;
    margin: 10px;
    padding: 10px;
}



.rowBorder {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.rowBorder .col-md-2:nth-child(2) .newShoppingsCardContainer {
    border-left: 1px solid rgb(201, 201, 201);

}