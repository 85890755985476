.newBestSellerCardContainer {
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    position: relative;
    z-index: 3;
    height: 410px;
}


.stars .fa-star {
    color: rgb(197, 197, 197);
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin: 0 2px;
}

.newBestSellerCardContainer .title {
    color: rgb(122, 121, 121);
    font-weight: 600;
    cursor: pointer;
}

.newBestSellerCardContainer .title:hover {
    color: #F33333;
}

.newBestSellerCardContainer .price {
    color: #F33333;
    font-weight: 600;
}



.newBestSellerCardContainer h5 {
    line-height: 1.5;
}

.addCartBest {
    color: rgb(122, 121, 121);
    font-weight: 600;
    border: 1px solid rgb(201, 201, 201);
    width: 50%;
    margin: 0 auto;
    padding: 8px 0;
    transform: translateY(calc(50% + 6px));
    background-color: #fff;
    cursor: pointer;
}

.newBestSellerCardContainer:hover .addCartBest {
    background-color: #e2d0d0;
    color: #fff;
    border: none;
}

.slick-container {
    /* border: 2px solid green; */
}



.slick-item-container {
    width: 295px !important;
}

.slick-track {
    /* border: 1px solid #fff; */
    margin: 20px 0;

}

.slick-slider {
    /* border: 2px solid green; */
}