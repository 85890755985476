.content{
    margin: 1.5rem;
}


.decration a{
    text-decoration: none;
    color:rgb(145, 144, 143)
}
.decration a:hover{
    color: brown;
}
.required{
    display: flex;
}
.from-control{
    width: 100%;
    border: none;
    background-color: #eee;
    border-radius: 3px;
    font-size: 12px;
    padding: 9px;
    height: 38px;
    outline: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */

}
.control-label{
    text-align: center;
    padding-top: 7px;
    margin-bottom: 0;
    padding-left: 3px;
    padding-right: 15px;
}
label{
    font-weight: 700;
    display: inline-block;
    max-width: 100%;
}
.form-group{
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.legender{
    border-bottom: 1px solid #e5e5e5;
    font-size: 18px;
    margin-bottom: 20px;

}
.radio-inline{
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
    padding-top: 7px;
}
.btn-primary{
    background-color: #999;
    text-transform: uppercase;
    border: none;
    margin-left: 10px;
}
.btn:hover{
    background-color: #999   ;
}
.panel-default {
    border: 1px solid #ddd;
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
}
.profile--panel .panel-heading {
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.profile--panel .panel-body {
    padding: 0;
}
.profile--panel .list-group-item {
    margin: 0;
    border: none;
    border-bottom: 1px solid #e2e2e2;
    background: none;
    border-radius: 0;
    font-size: 13px;
    padding: 6px 15px;
}
a, a:visited, a:active, a:link, a:focus {
    cursor: pointer;
    text-decoration: none;
}
a.list-group-item, button.list-group-item {
    color: #555;
}
.list-group-item {
    position: relative;
    display: block;
}
.panel-body .fa{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    font-size: 15px;
}
.list-group-item:hover{
    background-color: #e2e2e2;
}
.login-container{
    background-color: #794bc1;
    color: black;
    padding: 45px;
    width: 700px;
}
/* @media only screen and (max-width: 768px){
    .from-control{
        width: 100%;
        border: none;
        background-color: #eee;
        border-radius: 3px;
        font-size: 12px;
        padding: 9px;
        height: 38px;
        outline: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 2px solid red; */
    
    